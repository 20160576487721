import { createSelector } from '@ngrx/store';
import { selectAppState, AppState } from '..';
import { EventsState } from './events.state';

export const selectEventsState = createSelector(
  selectAppState,
  (state: AppState) => state.events
);

export const selectEventCancelCheckout = createSelector(
  selectEventsState,
  (state: EventsState) => state?.cancelCheckoutRequest ?? { cancelCheckout: false, redirectHome: false }
);

